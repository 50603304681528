<template>
  <section >
    <nav v-if="!$route.params.id" class="tabs-navbar khan-tabs-navbar" :class="{ dark: false }">
      <ul class="tabs-navbar-list">
        <li v-for="(item, index) in currentTabs" v-can="item.is_show" :key="index" class="tabs-navbar-list__item">
          <router-link class="tabs-navbar-list__item-link" :to="`/${item.link}`">
            {{ $t(item.name) }}
          </router-link>
        </li>
      </ul>
    </nav>

    <router-view />
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
        activeName: "",
        categoryA:[
        {
            name: 'message.notifications',
            link: 'systeamNotification',
            is_show: 'systeamNotification.index',
            slug: 'categoryA'
        },
        {
            name: 'message.notificationTypes',
            link: 'notificationTypes',
            is_show: 'notificationTypes.index',
            slug: 'categoryA'
          },
        {
            name: 'message.kpTemplates',
            link: 'kpTemplates',
            is_show: 'kPExampleTemplates.index',
            slug: 'categoryA'
          },
        {
            name: 'message.dealStages',
            link: 'dealStage',
            is_show: 'dealStages.index',
            slug: 'categoryA'
          },
        {
            name: 'message.paymentTypes',
            link: 'paymentTypes',
            is_show: 'paymentTypes.index',
            slug: 'categoryA'
          },
        {
            name: 'message.sources',
            link: 'sources',
            is_show: 'sources.index',
            slug: 'categoryA'
          },
        {
            name: 'message.companies',
            link: 'companies',
            is_show: 'companies.index',
            slug: 'categoryA'
          },
        {
            name: 'message.filial',
            link: 'filial',
            is_show: 'filials.index',
            slug: 'categoryA'
          },
        {
            name: 'message.countries',
            link: 'country',
            is_show: 'countries.index',
            slug: 'categoryA'
          },
        {
            name: 'message.cities',
            link: 'city',
            is_show: 'cities.index',
            slug: 'categoryA'
          },
        {
            name: 'message.categories',
            link: 'category',
            is_show: 'categories.index',
            slug: 'categoryA'
          },
        ],
        categoryB:[
              {
                  name: 'message.clientTypes',
                  link: 'clientTypes',
                  is_show: 'clientTypes.index',
                  slug: 'categoryB'
              },
              {
                  name: 'message.dealGoals',
                  link: 'dealGoals',
                  is_show: 'dealGoals.index',
                  slug: 'categoryB'
              },
              {
                  name: 'message.dealConditions',
                  link: 'dealConditions',
                  is_show: 'dealConditions.index',
                  slug: 'categoryB'
              },
              {
                  name: 'message.shipmentTerms',
                  link: 'shipmentTerms',
                  is_show: 'shipmentTerms.index',
                  slug: 'categoryB'
              },
              {
                  name: 'message.colors',
                  link: 'colors',
                  is_show: 'colors.index',
                  slug: 'categoryB'
              },
              {
                  name: 'message.packages',
                  link: 'packages',
                  is_show: 'packages.index',
                  slug: 'categoryB'
              },
              {
                  name: 'message.typeOfProjectWorks',
                  link: 'typeOfProjectWorks',
                  is_show: 'typeOfProjectWorks.index',
                  slug: 'categoryB'
              },
              {
                  name: 'message.managerTypes',
                  link: 'managerTypes',
                  is_show: 'managerTypes.index',
                  slug: 'categoryB'
              },
        ]
    };
  },
  computed: {
    ...mapGetters({
      // mode: "MODE"
    }),
    currentTabs() {
        if (this.$route.meta.category === "categoryA") {
            return this.categoryA;
        } else if (this.$route.meta.category === "categoryB") {
            return this.categoryB;
        }
    }
  },

  mounted() {
    this.activeName = this.$route.name;
  },
  methods: {
    handleClickTabs(tab) {
      // this.activeName = tab.name
      console.log(tab);
    },
  },
};
</script>

<style lang="scss">
.tabs-navbar {
    background-color: transparent;
    padding: 4px 0;
    border-bottom: 1px solid #ddd;
    margin: 0 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;

    // tabs-navbar-list
    &-list {
        position: relative;
        display: flex;
        align-items: center;
        gap: 2px;
        padding: 0;
        margin: 0;
        list-style: none;
        overflow-x: auto;
        white-space: nowrap;

        // scrool bar style
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }

        // tabs-navbar-list__item
        &__item {
            position: relative;
            z-index: 1;
            transition: all 0.2s ease-in-out;

            // tabs-navbar-list__item-link
            &:hover a {
                color: #fff;
                background-color: #0c67c9;
            }

            &-link {
                display: block;
                color: #727b89;
                font-size: 1rem;
                font-weight: 500;
                text-decoration: none;
                border-radius: 5px;
                padding: 4px 10px;
                transition: all 0.2s ease-in-out;

                &.router-link-exact-active {
                    color: #fff;
                    background-color: #0c67c9;
                }
            }
        }
    }

    // tabs-navbar__buttons
    &__buttons {
        flex-shrink: 0;
    }

    // dark mode
    &.dark {
        border-color: #44587c75;

        .el-button {
            color: #fff;
            background-color: #44587c75;
            border-color: #44587c75;
        }

        // tabs-navbar-list
        .tabs-navbar-list__item {
            color: #fff;
        }
    }
}
.d-modal-style .khan-tabs-navbar .tabs-navbar{
  border-bottom: 1px solid #8b8b8b;
}
.d-modal-style .khan-tabs-navbar .tabs-navbar-list__item-link{
  color: #d1d1d1;
}
</style>
